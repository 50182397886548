import React from "react";
import { graphql } from "gatsby";
import Single from "./Single";

function SinglePage({ data, pageContext }) {
  return <Single data={data} pageContext={pageContext} type="meeting" />;
}

export default SinglePage;

export const query = graphql`
  query($slug: String!) {
    article: nodeMeeting(fields: { slug: { eq: $slug } }) {
      drupal_id
      title
      field_date(formatString: "MMMM Do, YYYY")
      field_accordion_title
      fields {
        slug
      }
      body {
        processed
        summary
      }
      relationships {
        field_accordion_nodes {
          drupal_id
          title
          field_accordion_tab_content {
            processed
          }
          relationships {
            field_accordion_tab_files {
              drupal_id
              name
              relationships {
                field_media_document {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
    allMarkdownRemark(filter: { frontmatter: { slug: { eq: "meetings" } } }) {
      nodes {
        frontmatter {
          title
          slug
        }
      }
    }
    site {
      siteMetadata {
        drupalUrl
      }
    }
  }
`;
